import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import VectorTable from './VectorTable';
import vectorData from './vectors.json';
import countryCoordinates from './countryCoordinates.json';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapComponent = () => {
  const position = [0, 0];
  const mapRef = useRef(null);
  const [selectedVector, setSelectedVector] = useState(null);
  const [displayedVectors, setDisplayedVectors] = useState([]);

  useEffect(() => {
    // Sort vectors by date and take the first 100
    const sortedVectors = vectorData.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)).slice(0, 500);
    setDisplayedVectors(sortedVectors);
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    // Clear existing markers
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.CircleMarker) {
        mapRef.current.removeLayer(layer);
      }
    });

    // Add new markers
    displayedVectors.forEach(addVector);
  }, [displayedVectors]);

  useEffect(() => {
    if (mapRef.current) return;

    const map = L.map('map', {
      center: position,
      zoom: 2,
      worldCopyJump: true,
      minZoom: 1
    });
    mapRef.current = map;

    L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }).addTo(map);

    return () => {
      map.remove();
      mapRef.current = null;
    };
  }, []);

  const addVector = (vector) => {
    const from = L.latLng(countryCoordinates[vector.source] || [0, 0]);
    const to = L.latLng(countryCoordinates[vector.destination] || [0, 0]);
    
    if (!from.equals([0, 0]) && !to.equals([0, 0])) {
      const movingMarker = L.circleMarker(from, {
        radius: 2.5,
        color: '#004400',
        fillColor: '#00ff00',
        fillOpacity: 0.75,
        weight: .8
      }).addTo(mapRef.current);

      const tailMarkers = [];
      const tailLength = 30;

      for (let i = 0; i < tailLength; i++) {
        tailMarkers.push(L.circleMarker(from, {
          radius: 1.65 - (1.1 * i / tailLength),
          color: '#004400',
          fillColor: '#00ff00',
          fillOpacity: 0.5 - (0.4 * i / tailLength),
          weight: 1
        }).addTo(mapRef.current));
      }

      const animateMarker = (startTime, duration) => {
        const now = Date.now();
        const t = ((now - startTime) % duration) / duration;

        const newPos = L.latLng(
          from.lat + t * (to.lat - from.lat),
          from.lng + t * (to.lng - from.lng)
        );

        movingMarker.setLatLng(newPos);

        tailMarkers.forEach((marker, index) => {
          const delay = (index + 1) / tailLength;
          const tailT = (t - delay + 1) % 1;

          const tailPos = L.latLng(
            from.lat + tailT * (to.lat - from.lat),
            from.lng + tailT * (to.lng - from.lng)
          );

          marker.setLatLng(tailPos);

          const opacity = Math.max(0, 0.5 - (index / tailLength) - (0.5 - Math.sin(t * Math.PI) * 0.5));
          marker.setStyle({ fillOpacity: opacity * 0.5, opacity: opacity });
        });

        requestAnimationFrame(() => animateMarker(startTime, duration));
      };

      const randomDelay = Math.random() * 2000;
      const randomDuration = Math.random() * 2000 + 3000;
      setTimeout(() => animateMarker(Date.now(), randomDuration), randomDelay);

      L.circleMarker(from, {
        radius: 3,
        color: '#440000',
        fillColor: '#ff0000',
        fillOpacity: 0.6,
        weight: 1
      }).addTo(mapRef.current).bindPopup(`From: ${vector.source}`);
      
      L.circleMarker(to, {
        radius: 3,
        color: '#440000',
        fillColor: '#ff0000',
        fillOpacity: 0.6,
        weight: 1
      }).addTo(mapRef.current).bindPopup(`To: ${vector.destination}`);
    }
  };

  const handleVectorSelect = (vector) => {
    setSelectedVector(vector);
    const filteredVectors = vectorData
      .filter(v => v.source === vector.source || v.destination === vector.destination)
      .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
      .slice(0, 500);
    setDisplayedVectors(filteredVectors);
  };

  return (
    <div className="flex flex-col h-[calc(100vh-4rem)]">
      <div className="w-full h-[49vh]">
        <div id="map" className="w-full h-full"></div>
      </div>
      <div className="w-full flex-grow overflow-y-auto">
        <VectorTable vectors={vectorData} onVectorSelect={handleVectorSelect} selectedVector={selectedVector} />
      </div>
    </div>
  );
};

export default MapComponent;