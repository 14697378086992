import React, { useState, useEffect } from 'react';
import mockDataJson from './mockData2.json';

const DataExplanation = () => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow mb-4">
      <h3 className="text-lg font-semibold text-gray-200 mb-2">Understanding the Data</h3>
      <p className="text-gray-300 mb-2">
        This table presents data related to hybrid warfare activities extracted from news articles. Here's what each column represents:
      </p>
      <ul className="list-disc list-inside text-gray-300">
        <li><strong>Head:</strong> The headline or title of the news article.</li>
        <li><strong>URL:</strong> A link to the original news article.</li>
        <li><strong>Locations:</strong> The countries or regions mentioned in the article, often indicating the source and target of hybrid warfare activities.</li>
        <li><strong>Authors:</strong> The author(s) of the news article.</li>
        <li><strong>DateTime:</strong> The date and time when the article was published.</li>
        <li><strong>Summary:</strong> A brief overview of the article's content, highlighting key points related to hybrid warfare.</li>
      </ul>
      <p className="text-gray-300 mt-2">
        This data helps track and analyze patterns in hybrid warfare activities, information operations, and geopolitical tensions as reported in the media.
      </p>
    </div>
  );
};

const DataTable = () => {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showExplanation, setShowExplanation] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (process.env.NODE_ENV === 'development') {
        setData(mockDataJson);
        setIsLoading(false);
      } else {
        const response = await fetch('/get_data.php');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        setData(jsonData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`Failed to load data: ${error.message}`);
      setIsLoading(false);
    }
  };

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString();
  };

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) return <div className="text-center p-4 text-gray-300">Loading...</div>;
  if (error) return <div className="text-center p-4 text-red-400">{error}</div>;

  return (
    <div className="container mx-auto p-2">
      <h2 className="text-xl font-bold mb-3 text-gray-200">Hybrid Warfare Data from News</h2>
      <button
        onClick={() => setShowExplanation(!showExplanation)}
        className="mb-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
      >
        {showExplanation ? 'Hide' : 'Show'} Explanation
      </button>
      {showExplanation && <DataExplanation />}
      <div className="overflow-x-auto bg-gray-800 rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr>
              {['head', 'url', 'locations', 'authors', 'datetime', 'summary'].map((key) => (
                <th 
                  key={key} 
                  className={`px-3 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700 ${key === 'head' ? 'w-1/5' : ''}`}
                  onClick={() => sortData(key)}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)} {getSortDirection(key)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {currentItems.map((item, index) => (
              <tr key={item.id} className={index % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800'}>
                <td className="px-3 py-2 text-sm text-gray-300 w-1/5">
                  <div className="break-words">{item.head}</div>
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm">
                  <a href={item.url} className="text-blue-400 hover:underline" target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300">{item.locations}</td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300">{item.authors}</td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300">{formatDateTime(item.datetime)}</td>
                <td className="px-3 py-2 text-sm text-gray-300">{item.summary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3 flex justify-center">
        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`mx-1 px-3 py-1 border rounded text-sm ${
              currentPage === i + 1 
                ? 'bg-blue-600 text-white border-blue-600' 
                : 'bg-gray-800 text-gray-300 border-gray-600 hover:bg-gray-700'
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DataTable;