import React from 'react';
import trollid  from './trollid.jpg';

const HeroComponent = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl mb-6">
          Hybrid Warfare 
        </h1>
        <p className="text-xl text-gray-600 mb-8">
          Your go-to source for learning about modern information operations and hybrid warfare.
        </p>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex flex-col md:flex-row">
              <div className="md:w-2/3 md:pr-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">
                  Understanding Non-Democratic Strategies
                </h2>
                <p className="text-gray-600 mb-4">
                  We focus on revealing real-world examples and strategies used by non-democratic countries like Russia, China, Iran, North Korea, Belarus, and other authoritarian states.
                </p>
                <p className="text-gray-600 mb-4">
                  In today's connected world, these countries use a mix of military, economic, cyber, and information tactics to achieve their goals. From spreading false information and launching cyber attacks to economic threats and military actions, we explore how they destabilize global peace and democratic systems.
                </p>
              </div>
              <div className="md:w-1/3 mb-4 md:mb-0 md:pl-4">
                <img 
                  src={trollid} 
                  alt="Hybrid Warfare Illustration" 
                  className="rounded-lg shadow-lg w-full h-auto"
                />
              </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">
              Our Mission
            </h3>
            <p className="text-gray-600 mb-4">
              Our goal is to uncover these hidden activities, offering you detailed analysis, case studies, and the latest news. By understanding these hybrid threats, we can better prepare and protect ourselves.
            </p>
            <div className="mt-8">
              <p className="text-lg font-medium text-gray-900">
                Join us in exploring the complexities of hybrid warfare and stay informed on how non-democratic states are changing the global landscape with their covert actions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;