import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import '../index.css';
import MapComponent from './MapComponent';
import DataTable from './DataTable';
import HeroComponent from './HeroComponent';

// Placeholder components for each page
const News = () => <h2 className="text-2xl text-gray-200">News</h2>;
const Insight = () => <h2 className="text-2xl text-gray-200">Insight</h2>;

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-900 text-gray-200">
        {/* Header */}
        <header className="bg-gray-800 text-white p-4">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">Hybrid War</h1>
            <button 
              className="lg:hidden text-white focus:outline-none"
              onClick={toggleMenu}
            >
              {isMenuOpen ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </header>

        {/* Navigation */}
        <nav className={`bg-gray-800 text-white p-4 lg:block ${isMenuOpen ? 'block' : 'hidden'}`}>
          <ul className="lg:flex lg:space-x-6 flex flex-col lg:flex-row space-y-2 lg:space-y-0">
            <li><Link to="/" className="block hover:text-blue-300 hover:text-lg transition-all duration-300 ease-in-out" onClick={() => setIsMenuOpen(false)}>Main</Link></li>
            <li><Link to="/news" className="block hover:text-blue-300 hover:text-lg transition-all duration-300 ease-in-out" onClick={() => setIsMenuOpen(false)}>News</Link></li>
            <li><Link to="/data" className="block hover:text-blue-300 hover:text-lg transition-all duration-300 ease-in-out" onClick={() => setIsMenuOpen(false)}>Data</Link></li>
            <li><Link to="/articles" className="block hover:text-blue-300 hover:text-lg transition-all duration-300 ease-in-out" onClick={() => setIsMenuOpen(false)}>Articles</Link></li>
            <li><Link to="/map" className="block hover:text-blue-300 hover:text-lg transition-all duration-300 ease-in-out" onClick={() => setIsMenuOpen(false)}>Map</Link></li>
          </ul>
        </nav>

        {/* Main content */}
        <main className="flex-grow container mx-auto p-4">
          <Routes>
            <Route path="/" element={<HeroComponent />} />
            <Route path="/news" element={<News />} />
            <Route path="/data" element={<DataTable />} />
            <Route path="/insight" element={<Insight />} />
            <Route path="/map" element={<MapComponent />} />
          </Routes>
        </main>

        {/* Footer */}
        <footer className="bg-gray-800 text-white p-4 text-center">
          <p>&copy; 2024 (C) Hybrid War. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
};

export default Layout;