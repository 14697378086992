import React, { useState } from 'react';

const VectorTable = ({ vectors, onVectorSelect, selectedVector }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    vectors.sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = vectors.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-1">
      <h2 className="text-sm font-bold mb-1 text-gray-200">Vector Data Table</h2>
      <div className="overflow-x-auto bg-gray-800 rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr>
              {['From', 'To', 'Title', 'Date'].map((header, index) => (
                <th 
                  key={header} 
                  className="px-2 py-1 text-left text-[0.6rem] font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700"
                  onClick={() => sortData(['source', 'destination', 'head', 'datetime'][index])}
                >
                  {header} {getSortDirection(['source', 'destination', 'head', 'datetime'][index])}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {currentItems.map((item) => (
              <tr 
                key={item.uid} 
                className={`${
                  selectedVector && (item.source === selectedVector.source || item.destination === selectedVector.destination)
                    ? 'bg-blue-900'
                    : 'odd:bg-gray-900 even:bg-gray-800'
                } hover:bg-gray-700 cursor-pointer`}
                onClick={() => onVectorSelect(item)}
              >
                <td className="px-2 py-1 whitespace-nowrap text-[0.6rem] text-gray-300">{item.source}</td>
                <td className="px-2 py-1 whitespace-nowrap text-[0.6rem] text-gray-300">{item.destination}</td>
                <td className="px-2 py-1 text-[0.6rem] text-gray-300 truncate max-w-[150px]">{item.head}</td>
                <td className="px-2 py-1 whitespace-nowrap text-[0.6rem] text-gray-300">{item.datetime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-1 flex justify-center">
        {Array.from({ length: Math.ceil(vectors.length / itemsPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`mx-0.5 px-1.5 py-0.5 border rounded text-[0.6rem] ${
              currentPage === i + 1 
                ? 'bg-blue-600 text-white border-blue-600' 
                : 'bg-gray-800 text-gray-300 border-gray-600 hover:bg-gray-700'
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default VectorTable;